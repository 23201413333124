import React, { useState, useEffect, useRef } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Button, 
  ListItemText,
  Paper,
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
  ThemeProvider,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Fab,
} from '@mui/material';
import { Chat, Speed, CheckCircle, Lock, ArrowForward, Menu as MenuIcon, SecurityOutlined } from '@mui/icons-material';
import { Link as ScrollLink, Element as ScrollElement } from 'react-scroll';

import logo from './assets/AAS-logo-white.png';
import logoBrown from './assets/aa-logo.png'
import theme, { authTextFieldStyle } from './theme';
import icon from './assets/aas-icon.png';
import darbyGif from './assets/darby12.gif'; // Add this line at the top with other imports
import stripeGif from './assets/stripeGIF.gif'; // Add this import at the top of your file
import filteringGif from './assets/filteringGIF.gif'; // Add this import at the top of your file
import { sendContactEmail } from './functions';
import police from './assets/police-officer.svg';
import programManager from './assets/user-cog.svg';
import policy from './assets/policy.svg';
import robot from './assets/artificial-intelligence.svg';
import parralax from './assets/background4.jpg';
import bg from './assets/background2.jpg';
import cursor from './assets/cursor.svg';

// Add this constant at the top of your file, after the imports
const darkPurple = '#000000';

const MainPage: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const sectionColors = ['#FFFFFF', '#30242e', '#F5F5F5', '#E0E0E0', '#D3D3D3', '#C0C0C0'];

  const getTextColor = (bgColor: string) => {
    switch (bgColor) {
      case '#FFFFFF': return '#000000';
      case '#808080': return '#FFFFFF';
      case '#000000': return '#FFFFFF';
      default: return '#000000';
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getBackgroundStyle = () => {
    return { backgroundColor: sectionColors[0] }; // Always white background
  };

  const interpolateColor = (color1: string, color2: string, factor: number) => {
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);
    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);

    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const getOpacity = (index: number) => {
    if (index <= 1) return 1; // First two sections always fully opaque
    const sectionElement = sectionRefs.current[index];
    if (!sectionElement) return 0;

    const rect = sectionElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Adjusted values for different sections
    const fadeInStart = index === 2 ? windowHeight * 1.0 : 
                        index === 4 ? windowHeight * 1.2 : 
                        index === 5 ? windowHeight * 1.0 : windowHeight * 1.2;
    const fadeInEnd = index === 2 ? windowHeight * 0.4 : 
                      index === 4 ? windowHeight * 0.5 :
                      index === 5 ? windowHeight * 0.4 :
                      windowHeight * 0.2;
    const fadeOutStart = index === 2 ? (isMobile ? -windowHeight * 0.8 : windowHeight * 0.05) :
                         index === 4 ? (isMobile ? -windowHeight * 0.4 : windowHeight * 0.2) : // Changed for pricing section on mobile
                         index === 5 ? (isMobile ? -windowHeight * 0.8 : -windowHeight * 0.4) :
                         -windowHeight * 0.2;
    const fadeOutEnd = index === 2 ? (isMobile ? -windowHeight * 1.3 : -windowHeight * 0.6) :
                       index === 4 ? (isMobile ? -windowHeight * 0.9 : -windowHeight * 0.6) : // Changed for pricing section on mobile
                       index === 5 ? (isMobile ? -windowHeight * 1.5 : -windowHeight * 1.0) :
                       -windowHeight * 0.8;

    if (rect.top <= fadeInEnd && rect.top >= fadeOutStart) return 1;
    if (rect.top > fadeInStart || rect.bottom < fadeOutEnd) return 0;

    if (rect.top > fadeInEnd && rect.top <= fadeInStart) {
      return 1 - (rect.top - fadeInEnd) / (fadeInStart - fadeInEnd);
    }

    if (rect.top < fadeOutStart && rect.top >= fadeOutEnd) {
      return (rect.top - fadeOutEnd) / (fadeOutStart - fadeOutEnd);
    }

    return 0;
  };

  const getScale = (index: number) => {
    const opacity = getOpacity(index);
    return 0.8 + (0.2 * opacity); // This will scale from 0.8 to 1 as opacity goes from 0 to 1
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navItems = [
    { name: 'AI Expertise', to: 'section-1' },
    { name: 'Why Darby?', to: 'section-2' },
    { name: 'For Professionals', to: 'section-3' },
    { name: 'Pricing', to: 'section-4' },
    { name: 'About & Contact', to: 'section-5' },
  ];

  const buttonStyle = {
    py: 1.5,
    px: 3,
    color: '#FF0000', // Changed to red
    fontSize: '1rem',
    fontWeight: 'bold',
    borderColor: '#FF0000', // Changed to red
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': { 
      backgroundColor: 'rgba(255, 0, 0, 0.1)', // Changed hover effect to light red
    },
  };

  const getHeroContentOpacity = () => {
    const fadeStart = window.innerHeight * 0.1;
    const fadeEnd = window.innerHeight * 0.5;
    const opacity = 1 - Math.min(Math.max((scrollPosition - fadeStart) / (fadeEnd - fadeStart), 0), 1);
    return opacity;
  };

  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [contactForm, setContactForm] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    comment: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleContactDialogOpen = () => {
    setOpenContactDialog(true);
  };

  const handleContactDialogClose = () => {
    setOpenContactDialog(false);
  };

  const handleContactFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setContactForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleContactFormSubmit = async () => {
    // Validate all fields are filled
    if (Object.values(contactForm).some(value => value === '')) {
      alert('Please fill in all fields');
      return;
    }

    // Validate phone number
    if (!/^\d{10}$/.test(contactForm.phoneNumber)) {
      alert('Please enter a valid 10-digit phone number');
      return;
    }

    // Validate email
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(contactForm.email)) {
      alert('Please enter a valid email address');
      return;
    }

    setIsLoading(true);

    try {
      const success = await sendContactEmail(contactForm);
      if (success) {
        setShowSuccessMessage(true);
        handleContactDialogClose();
        // Reset form
        setContactForm({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          comment: '',
        });
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      console.error('Error submitting contact form:', error);
      alert('An error occurred while sending your message. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSuccessMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccessMessage(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const offWhiteColor = '#F8F8F8'; // Define the off-white color

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...getBackgroundStyle(),
          minHeight: '100vh',
          position: 'relative',
          overflow: 'hidden', // Changed from 'visible' to 'hidden'
          paddingTop: '80px',
          paddingBottom: 0, // Removed padding at the bottom
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar 
          position="fixed" 
          elevation={0} 
          sx={{ 
            backgroundColor: darkPurple, // Changed from '#000000' to darkPurple
            height: isMobile ? '60px' : '80px',
            borderBottom: '1px solid white',
          }}
        >
          <Toolbar sx={{ 
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: isMobile ? '60px' : '80px',
            padding: '0 24px',
            height: '100%',
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img 
                src={logo} 
                alt="Aging Aircraft Solutions Logo" 
                style={{ height: isMobile ? '40px' : '60px' }} // Reduced from 60px to 40px for mobile
              />
            </Box>
            {isMobile ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {navItems.map((item) => (
                  <ScrollLink 
                    key={item.to}
                    to={item.to} 
                    smooth={true} 
                    duration={500} 
                    offset={item.to === 'section-1' ? 80 : 
                            item.to === 'section-2' ? (isMobile ? -100 : -170) : 
                            item.to === 'section-3' ? (isMobile ? -150 : -140) :
                            item.to === 'section-4' ? (isMobile ? -500 : 150) : // Changed from -300 to -200 for mobile
                            250}
                  >
                    <Button 
                      sx={{ 
                        color: 'white',
                        mx: 1,
                        textTransform: 'none',
                        fontSize: '1.1rem',
                        padding: '8px 16px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                          bgcolor: 'rgba(255, 255, 255, 0.1)',
                        },
                      }}
                    >
                      {item.name}
                    </Button>
                  </ScrollLink>
                ))}
              </Box>
            )}
            {!isMobile && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button 
                  variant="outlined"
                  sx={{
                    ...buttonStyle,
                    borderColor: '#FF0000', // Changed to red
                    color: '#FF0000', // Changed to red
                    borderRadius: '28px',
                    padding: '12px 24px',
                    fontSize: '1.1rem',
                    textTransform: 'none',
                    '&:hover': { 
                      backgroundColor: 'rgba(255, 0, 0, 0.1)', // Changed hover effect to light red
                    },
                  }}
                  href="https://app.hellodarby.us/"
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<ArrowForward />}
                >
                  Start your free trial
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {navItems.map((item) => (
            <MenuItem key={item.to} onClick={handleMenuClose}>
              <ScrollLink 
                to={item.to} 
                smooth={true} 
                duration={500} 
                offset={item.to === 'section-1' ? 80 : 
                        item.to === 'section-2' ? (isMobile ? -120 : -280) : 
                        item.to === 'section-3' ? (isMobile ? -100 : 20) :
                        item.to === 'section-4' ? (isMobile ? 75 : 250) : // Changed from 0 to -200 for mobile
                        item.to === 'section-5' ? (isMobile ? 180 : -100) :
                        -100}
              >
                {item.name}
              </ScrollLink>
            </MenuItem>
          ))}
        </Menu>
        
        {['hero', 'ai-expertise', 'why-darby', 'for-professionals', 'pricing', 'about-contact'].map((section, index) => (
          <Box
            key={section}
            ref={(el: HTMLDivElement | null) => sectionRefs.current[index] = el}
            sx={{
              height: index === 1 ? '70vh' : 
                      index === 2 ? (isMobile ? '160vh' : '125vh') :
                      index === 3 ? (isMobile ? '110vh' : '55vh') :
                      index === 4 ? (isMobile ? '110vh' :'75vh') : 
                      index === 5 ? (isMobile ? '145vh' : '100vh') :
                      '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: index === 0 ? 'white' : getTextColor(sectionColors[index]),
              position: index === 0 ? 'sticky' : 'relative',
              top: index === 0 ? 0 : 'auto',
              zIndex: index === 0 ? 2 : 3,
              backgroundColor: index === 0 ? darkPurple : offWhiteColor, // Changed from '#000000' to darkPurple
              opacity: 1,
              mt: isMobile && index > 2 ? '0px' : 0,
            }}
          >
            <ScrollElement name={`section-${index}`}>
              <Container maxWidth={false} sx={{ maxWidth: '1600px', px: { xs: 2, sm: 4, md: 6 } }}>
                <Box 
                  minHeight="100vh"
                  display="flex" 
                  flexDirection="column" 
                  justifyContent="center"
                  sx={{
                    opacity: index === 0 ? getHeroContentOpacity() : 
                             index === 1 ? getOpacity(index) : 
                             1, // Sections 2-5 (index 3-6) content always fully opaque
                  }}
                >
                  {section === 'hero' && (
                    <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        zIndex: 1,
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <Box
                        sx={{
                          color: '#FFFFFF',
                          maxWidth: isMobile ? '90%' : '600px',
                          width: '95%',
                          textAlign: 'left',
                          marginTop: isMobile ? '100px' : '30px',
                          marginLeft: isMobile ? '20px' : '170px',
                          transform: 'translateY(-50px)',
                          opacity: getHeroContentOpacity(),
                          position: 'relative', // Add this to position the cursor icon
                        }}
                      >
                        <Typography 
                          variant="h1" 
                          gutterBottom 
                          color="#FFFFFF" 
                          sx={{ 
                            fontSize: isMobile ? '3.5rem' : '6rem',
                            fontWeight: 900,
                            lineHeight: 1.1,
                          }}
                        >
                          Meet DARBy
                        </Typography>
                        <Typography 
                          variant="h4" 
                          paragraph 
                          color="#FFFFFF" 
                          sx={{ 
                            fontSize: isMobile ? '1.5rem' : '2rem', 
                            mb: isMobile ? 4 : 6,
                            position: 'relative',
                            display: 'inline-block',
                          }}
                        >
                          Your&nbsp;{' '}
                          <Box
                            component="span"
                            sx={{
                              position: 'relative',
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-5px',
                                left: '-7px',
                                right: '-7px',
                                bottom: '-5px',
                                border: '1px solid #FF0000',
                                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                                zIndex: -1,
                              },
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                width: '8px',
                                height: '8px',
                                backgroundColor: '#FF0000',
                                borderRadius: '50%',
                                top: '-7px',
                                left: '-10px',
                                boxShadow: `
                                  ${isMobile ? '136px' : '176px'} 0 0 #FF0000,
                                  0 ${isMobile ? '35px' : '42px'} 0 #FF0000,
                                  ${isMobile ? '136px' : '176px'} ${isMobile ? '35px' : '42px'} 0 #FF0000
                                `,
                              },
                            }}
                          >
                            AI-powered
                          </Box>
                          {' '} &nbsp;DoD Acquisition Reference Bot
                        </Typography>
                        <Box mt={isMobile ? 4 : 8} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                          <Button 
                            variant="outlined"
                            size="large"
                            endIcon={<ArrowForward />}
                            sx={{
                              ...buttonStyle,
                              borderRadius: '28px',
                              padding: isMobile ? '10px 20px' : '15px 40px',
                              fontSize: isMobile ? '1rem' : '1.4rem',
                              textTransform: 'none',
                              borderColor: '#FF0000',
                              color: '#FF0000',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                              },
                              mb: isMobile ? 0 : 0, // Changed from 2 to 0
                              mr: isMobile ? 2 : 3,
                            }}
                            href="https://app.hellodarby.us/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Start your free trial
                          </Button>
                          <Button
                            variant="outlined"
                            size="large"
                            sx={{
                              borderColor: '#FFFFFF',
                              color: '#FFFFFF',
                              borderRadius: '28px',
                              padding: isMobile ? '10px 20px' : '15px 40px',
                              fontSize: isMobile ? '1rem' : '1.4rem',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              },
                            }}
                            onClick={() => {
                              const element = document.getElementById('section-1');
                              if (element) {
                                const navbarHeight = 80;
                                const additionalOffset = isMobile ? 50 : 80;
                                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                                const offsetPosition = elementPosition - navbarHeight - additionalOffset;

                                window.scrollTo({
                                  top: offsetPosition,
                                  behavior: 'smooth'
                                });
                              }
                            }}
                          >
                            Learn More
                          </Button>
                        </Box>
                        {/* Add cursor icon */}
                        <Box
                          component="img"
                          src={cursor}
                          alt="Cursor"
                          sx={{
                            position: 'fixed',
                            bottom: isMobile ? '40%' : '150px',
                            right: isMobile ? '40%' : '290px',
                            width: isMobile ? '30px' : '45px',
                            height: 'auto',
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {section === 'ai-expertise' && (
                    <Box id="section-1" sx={{ 
                      height: '50vh',
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent: 'center', 
                      py: 4,
                      backgroundColor: offWhiteColor,
                      width: '100%',
                    }}>
                      <Container maxWidth={false} sx={{ maxWidth: '1600px', px: { xs: 2, sm: 4, md: 6 } , backgroundColor: offWhiteColor}}>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: isMobile ? 'column' : 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          backgroundColor: offWhiteColor,
                        }}>
                          <Box sx={{ 
                            width: isMobile ? '100%' : '45%',
                            display: 'flex', 
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center', // Center vertically
                            mb: isMobile ? 4 : 0,
                            ml: isMobile ? 0 : -10 // Remove left margin on mobile
                          }}>
                            <img 
                              src={robot} 
                              alt="Aging Aircraft Solutions Logo" 
                              style={{ 
                                maxWidth: '100%', 
                                height: 'auto',
                                maxHeight: isMobile ? '150px' : '700px',
                              }} 
                            />
                          </Box>
                          <Box sx={{ 
                            width: isMobile ? '100%' : '55%',
                            textAlign: 'left',
                            pl: isMobile ? 0 : 2
                          }}>
                            <Typography 
                              variant="h3" 
                              gutterBottom 
                              color={darkPurple}
                              fontWeight="bold" 
                              sx={{
                                mb: '40px',
                                fontSize: isMobile ? '1.8rem' : '3.5rem',
                              }}
                            >
                              Streamline Acquisition with AI Expertise
                            </Typography>
                            <Typography 
                              variant="body1" 
                              paragraph 
                              sx={{ 
                                fontSize: isMobile ? '1rem' : '1.5rem',
                                lineHeight: 1.6,
                                color: {darkPurple},
                              }}
                            >
                              Introducing DARBy, the AI chat assistant designed to enhance the productivity of U.S. Department of Defense acquisition professionals. DARBy is deeply knowledgeable in DoD acquisition policy, guidebooks, and standards, providing real-time guidance to help you navigate complex acquisition processes with ease.
                            </Typography>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  )}
                  {section === 'why-darby' && (
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-start', 
                      position: 'relative', 
                      width: '100%', 
                      height: isMobile ? 'auto' : '80vh',
                      mt: isMobile ? '-40px' : '-300px',
                      px: 0,
                      opacity: getOpacity(index),
                      transform: `scale(${getScale(index)})`,
                      transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
                      mb: isMobile ? 0 : undefined, // Remove bottom margin on mobile
                    }}>
                      <Typography variant="h3" fontWeight="bold" sx={{
                        fontSize: isMobile ? '1.8rem' : '3.5rem',
                        mb: isMobile ? 3 : 6,
                        color: darkPurple,
                        ml: isMobile ? '5%' : '5%',
                      }}>
                        Why Choose DARBy?
                      </Typography>
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        width: '100%',
                      }}>
                        <Box
                          sx={{
                            width: isMobile ? '100%' : '50%',
                            padding: '25px',
                            color: darkPurple,
                            height: isMobile ? 'auto' : '430px',
                            mb: isMobile ? 4 : 0,
                            maxHeight: isMobile ? 'none' : '60vh',
                            overflowY: isMobile ? 'visible' : 'auto',
                            mr: isMobile ? 0 : 4,
                            ml: isMobile ? 0 : '5%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Box sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            width: '100%',
                            height: '100%',
                            alignContent: 'center',
                          }}>
                            {isMobile ? (
                              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                {/* Mobile layout: Single column */}
                                {[
                                  { title: 'Accelerate Decision-Making', detail: 'Get immediate answers to your acquisition questions.' },
                                  { title: 'Improve Work Quality', detail: 'Ensure that your work aligns with the latest DoD standards and policies.' },
                                  { title: 'Boost Work Efficiency', detail: 'Save hours of research and increase your productivity.' },
                                  { title: 'Document Type Filtering', detail: 'Choose to search through policy documents, guidebook documents, or both.' },
                                  { title: 'Military Branch Filtering', detail: 'Filter results by Army, Navy, Air Force, or Department of Defense (encompassing all branches).' },
                                  { title: 'Customize Your Chatbot', detail: 'Tell DARBy about your position and goals to fine tune your experience.' },
                                ].map((point, index) => (
                                  <Box key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                      <CheckCircle sx={{ 
                                        mr: 1, 
                                        color: '#FF0000',
                                        borderRadius: '50%',
                                        padding: '2px',
                                        fontSize: '1.2rem',
                                      }} />
                                      <Typography variant="body1" sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: darkPurple,
                                      }}>
                                        {point.title}
                                      </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{
                                      fontSize: '1rem',
                                      ml: 4,
                                      mt: 1,
                                      color: darkPurple,
                                    }}>
                                      {point.detail}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            ) : (
                              <>
                                {/* Desktop layout: Two columns */}
                                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                  {[
                                    { title: 'Accelerate Decision-Making', detail: 'Get immediate answers to your acquisition questions.' },
                                    { title: 'Improve Work Quality', detail: 'Ensure that your work aligns with the latest DoD standards and policies.' },
                                    { title: 'Boost Work Efficiency', detail: 'Save hours of research and increase your productivity.' },
                                  ].map((point, index) => (
                                    <Box key={index} sx={{ mb: 3, display: 'flex', flexDirection: 'column' }}>
                                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <CheckCircle sx={{ 
                                          mr: 1, 
                                          color: '#FF0000',
                                          borderRadius: '50%',
                                          padding: '2px',
                                          fontSize: '1.8rem',
                                        }} />
                                        <Typography variant="body1" sx={{
                                          fontSize: '1.4rem',
                                          fontWeight: 'bold',
                                          color: darkPurple,
                                        }}>
                                          {point.title}
                                        </Typography>
                                      </Box>
                                      <Typography variant="body2" sx={{
                                        fontSize: '1.2rem',
                                        ml: 4,
                                        mt: 1,
                                        color: darkPurple,
                                      }}>
                                        {point.detail}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                  {[
                                    { title: 'Document Type Filtering', detail: 'Choose to search through policy documents, guidebook documents, or both.' },
                                    { title: 'Military Branch Filtering', detail: 'Filter results by Army, Navy, Air Force, or Department of Defense (encompassing all branches).' },
                                    { title: 'Customize Your Chatbot', detail: 'Tell DARBy about your position and goals to fine tune your experience.' },
                                  ].map((point, index) => (
                                    <Box key={index} sx={{ mb: 3, display: 'flex', flexDirection: 'column' }}>
                                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <CheckCircle sx={{ 
                                          mr: 1, 
                                          color: '#FF0000',
                                          borderRadius: '50%',
                                          padding: '2px',
                                          fontSize: '1.8rem',
                                        }} />
                                        <Typography variant="body1" sx={{
                                          fontSize: '1.4rem',
                                          fontWeight: 'bold',
                                          color: darkPurple,
                                        }}>
                                          {point.title}
                                        </Typography>
                                      </Box>
                                      <Typography variant="body2" sx={{
                                        fontSize: '1.2rem',
                                        ml: 4,
                                        mt: 1,
                                        color: darkPurple,
                                      }}>
                                        {point.detail}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                        {!isMobile && (
                          <Box sx={{ width: '40%' }}>
                            <img 
                              src={darbyGif} 
                              alt="DARBy Demo" 
                              style={{ 
                                width: '100%',
                                height: 'auto',
                                borderRadius: '16px',
                                maxHeight: '60vh',
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                        )}
                        {isMobile && (
                          <Box sx={{ width: '100%', mt: 0, mb: 4 }}> // Changed mt from 4 to 0, added mb: 4
                            <img 
                              src={darbyGif} 
                              alt="DARBy Demo" 
                              style={{ 
                                width: '100%', 
                                height: 'auto',
                                borderRadius: '16px',
                                marginTop: '-50px', // Added this line to move the GIF up by 50px
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                  {section === 'for-professionals' && (
                    <Box
                      sx={{
                        height: isMobile ? 'calc(100vh)' : 'calc(100vh - 270px)',
                        width: '100vw',
                        backgroundImage: `url(${parralax})`,
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        left: '50%',
                        right: '50%',
                        marginLeft: '-50vw',
                        marginRight: '-50vw',
                        marginTop: isMobile ? -12 : '-380px',
                        paddingTop: isMobile ? '40px' : '80px',
                        marginBottom: isMobile ? 0 : '80px', // Remove bottom margin on mobile
                      }}
                    >
                      <Container maxWidth={false} sx={{ maxWidth: '1600px', height: '100%', position: 'relative' }}>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          width: '100%',
                          height: '100%',
                        }}>
                          <Typography variant="h3" gutterBottom fontWeight="bold" sx={{
                            fontSize: isMobile ? '1.8rem' : '3.5rem',
                            color: 'white',
                            mb: isMobile ? 4 : 6,
                            textAlign: 'center',
                            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                          }}>
                            Tailored for Acquisition Professionals
                          </Typography>
                          <Box sx={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: isMobile ? 'column' : 'row',
                            flexWrap: 'wrap', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: isMobile ? 2 : 3,
                          }}>
                            {[
                              { title: 'Contracting Officers', content: 'Quickly assess relevant policies and standards to streamline contract management.', icon: police },
                              { title: 'Program Managers', content: 'Get real-time support to keep acquisition projects on track.', icon: programManager },
                              { title: 'Policy Advisors and Analysts', content: 'Rely on DARBy to provide insights and interpretations of key regulations and policies.', icon: policy },
                            ].map((item, index) => (
                              <Box
                                key={index}
                                sx={{
                                  width: isMobile ? '100%' : 'calc(33.33% - 16px)',
                                  height: isMobile ? 'auto' : '270px',
                                  backgroundColor: 'white',
                                  borderRadius: '16px',
                                  padding: isMobile ? '15px' : '20px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                  mb: isMobile ? 2 : 0,
                                }}
                              >
                                {item.icon && (
                                  <Box sx={{ mb: isMobile ? 1 : 2 }}>
                                    <img src={item.icon} alt={item.title} style={{ 
                                      width: isMobile ? '2rem' : '3rem',
                                      height: isMobile ? '2rem' : '3rem',
                                      filter: `brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(1805%) hue-rotate(268deg) brightness(94%) contrast(88%)` 
                                    }} />
                                  </Box>
                                )}
                                <Typography variant="h5" gutterBottom sx={{ 
                                  color: darkPurple, 
                                  fontWeight: 'bold',
                                  fontSize: isMobile ? '1.2rem' : '1.5rem',
                                }}>
                                  {item.title}
                                </Typography>
                                <Typography variant="body1" sx={{ 
                                  color: darkPurple,
                                  fontSize: isMobile ? '1rem' : '1.3rem',
                                }}>
                                  {item.content}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  )}
                  {section === 'pricing' && (
                    <Box
                      sx={{
                        // ... other styles
                        opacity: getOpacity(index),
                        transform: `scale(${getScale(index)})`,
                        transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
                        marginTop: isMobile ? '-200px' : '80px', // Move the background up 100px on mobile
                        height: isMobile ? 'calc(120vh + 450px)' : 'auto', // Increased height for mobile
                        position: 'relative',
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        minHeight: isMobile ? 'calc(120vh + 450px)' : '100vh',
                        position: 'relative', 
                        mt: isMobile ? '100px' : '80px', // Keep content in original position
                        mb: isMobile ? '0px' : '310px',
                        width: '100%',
                      }}>
                        <Box 
                          sx={{ 
                            maxWidth: '1600px',
                            width: '90%',
                            position: 'relative',
                            zIndex: 3,
                          }}
                        >
                          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                            <Box width={isMobile ? '100%' : '50%'} pr={isMobile ? 0 : 4}>
                              <Box display="flex" alignItems="baseline" mb={2}>
                                <Typography variant="h3" color={darkPurple} fontWeight="bold" sx={{
                                  fontSize: isMobile ? '1.8rem' : '3.5rem',
                                  mr: 2,
                                }}>
                                  Try DARBy
                                </Typography>
                                <Typography variant="h3" color="#FF0000" fontWeight="bold" sx={{
                                  fontSize: isMobile ? '1.8rem' : '3.5rem',
                                }}>
                                  30 days free
                                </Typography>
                              </Box>
                              <Typography variant="h6" gutterBottom color={darkPurple} sx={{
                                fontSize: isMobile ? '1.2rem' : '1.8rem',
                                mb: 4,
                              }}>
                                Then $50.00 per month
                              </Typography>
                              <Button 
                                variant="contained" 
                                size="large"
                                href="https://app.hellodarby.us/"
                                target="_blank"
                                rel="noopener noreferrer"
                                endIcon={<ArrowForward />}
                                sx={{
                                  ...buttonStyle,
                                  bgcolor: '#FF0000',
                                  color: 'white',
                                  borderRadius: '28px',
                                  padding: isMobile ? '10px 20px' : '15px 30px',
                                  fontSize: isMobile ? '1rem' : '1.3rem',
                                  textTransform: 'none',
                                  '&:hover': { 
                                    bgcolor: '#CC0000',
                                  },
                                  mb: 4,
                                  mt: 4,
                                }}
                              >
                                Start your free trial
                              </Button>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Lock sx={{ color: darkPurple, mr: 1, fontSize: isMobile ? '1rem' : '1.3rem' }} />
                                <Typography variant="body2" color="#FF0000" sx={{
                                  fontSize: isMobile ? '0.9rem' : '1.3rem',
                                }}>
                                  Secure payments powered by Stripe
                                </Typography>
                              </Box>
                              <Typography variant="body2" color={darkPurple} sx={{ 
                                fontSize: isMobile ? '0.8rem' : '1.1rem',
                              }}>
                                We use Stripe for secure, reliable payment processing. Your payment information is protected with industry-standard encryption.
                              </Typography>
                            </Box>
                            <Box width={isMobile ? '100%' : '50%'} mt={isMobile ? 4 : 0}>
                              <Box display="flex" flexWrap="wrap" justifyContent="space-between" height={isMobile ? 'auto' : '100%'}>
                                {[
                                  'Full access to Darby AI',
                                  'Unlimited queries',
                                  '24/7 availability',
                                  'Cancel anytime'
                                ].map((feature, index) => (
                                  <Box 
                                    key={index} 
                                    sx={{
                                      width: isMobile ? '100%' : 'calc(50% - 12px)',
                                      height: isMobile ? '100px' : 'calc(50% - 12px)', // Fixed height for mobile
                                      mb: isMobile ? 2 : 3,
                                      p: 2,
                                      borderRadius: '16px',
                                      border: '1px solid #000000',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <CheckCircle sx={{ color: '#FF0000', fontSize: isMobile ? '2rem' : '2.5rem', mb: 1 }} />
                                    <Typography variant="body1" color={darkPurple} sx={{ 
                                      fontSize: isMobile ? '1rem' : '1.4rem',
                                      fontWeight: 'bold',
                                      textAlign: 'left',
                                    }}> 
                                      {feature}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {section === 'about-contact' && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '120vh',
                        position: 'relative',
                        mt: isMobile ? '250px' : '350px', // Changed from '250px' to '650px' for mobile
                        mb: isMobile ? '100px' : '310px',
                        width: '100%',
                        opacity: getOpacity(index),
                        transform: `scale(${getScale(index)})`,
                        transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
                      }}
                    >
                      {/* About section */}
                      <Box 
                        sx={{ 
                          maxWidth: '1600px',
                          width: '90%',
                          backgroundColor: darkPurple,
                          borderRadius: '24px',
                          padding: isMobile ? '40px' : '60px',
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          minHeight: isMobile ? 'auto' : '700px',
                          mb: 4,
                        }}
                      >
                        <Box 
                          width={isMobile ? '100%' : '50%'} 
                          pr={isMobile ? 0 : 4}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography variant="h3" gutterBottom color="white" fontWeight="bold" sx={{
                            fontSize: isMobile ? '1.8rem' : '3.5rem',
                            mb: isMobile ? 3 : 4,
                          }}>
                            Empowering Smarter Acquisition with AI
                          </Typography>
                          <Typography variant="body1" color="white" sx={{ 
                            fontSize: isMobile ? '0.9rem' : '1.2rem',
                            mb: isMobile ? 2 : 3,
                          }}>
                            Aging Aircraft Solutions (formerly Aging Aircraft Consulting) is a small business engineering services company based in Georgia. We strive to provide the highest quality engineering, logistics and product support to our customers.
                          </Typography>
                        </Box>
                        <Box 
                          width={isMobile ? '100%' : '50%'} 
                          display="flex" 
                          flexDirection="column" 
                          justifyContent="space-between"
                        >
                          <Box 
                            sx={{ 
                              backgroundColor: 'white', 
                              borderRadius: '16px', 
                              p: isMobile ? 3 : 4,
                              mb: isMobile ? 2 : 3,
                              height: isMobile ? 'auto' : '48%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography variant="h5" gutterBottom color={darkPurple} fontWeight="bold" sx={{
                              fontSize: isMobile ? '1.3rem' : '1.8rem',
                              mb: isMobile ? 2 : 3,
                            }}>
                              Our Experience
                            </Typography>
                            <Typography variant="body1" color={darkPurple} sx={{ 
                              fontSize: isMobile ? '0.9rem' : '1.1rem',
                            }}>
                              We have been in business for over 20 years, focusing on sustaining aging aircraft for military and commercial customers. Our extensive experience allows us to provide unparalleled support and solutions.
                            </Typography>
                          </Box>
                          <Box 
                            sx={{ 
                              backgroundColor: 'white', 
                              borderRadius: '16px', 
                              p: isMobile ? 3 : 4,
                              height: isMobile ? 'auto' : '48%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography variant="h5" gutterBottom color={darkPurple} fontWeight="bold" sx={{
                              fontSize: isMobile ? '1.3rem' : '1.8rem',
                              mb: isMobile ? 2 : 3,
                            }}>
                              Our Mission
                            </Typography>
                            <Typography variant="body1" color={darkPurple} sx={{ 
                              fontSize: isMobile ? '0.9rem' : '1.1rem',
                            }}>
                              We believe in our customer's mission and that there are better, smarter, and more cost-effective ways to maintain and sustain aircraft and critical assets. We are committed to providing quality, dependable support built on a foundation of trust.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* Contact section */}
                      <Box 
                        sx={{ 
                          display: 'flex',
                          justifyContent: 'center',
                          width: '90%',
                          maxWidth: '1200px',
                          mt: '50px', // Changed back to original value
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            backgroundColor: 'white',
                            borderRadius: '24px',
                            padding: '50px',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            height: '280px',
                          }}
                        >
                          <Typography variant="h5" color={darkPurple} sx={{ 
                            mb: 4, // Increased margin bottom
                            maxWidth: '600px', 
                            fontWeight: 'bold', 
                            fontSize: isMobile ? '1.3rem' : '1.6rem' // Slightly increased font size
                          }}>
                            Reach out to us with any questions. One of our DARBy experts will respond as soon as possible.
                          </Typography>
                          <Button 
                            variant="contained" 
                            size="large"
                            onClick={handleContactDialogOpen}
                            sx={{
                              backgroundColor: '#FF0000',
                              color: 'white',
                              '&:hover': { backgroundColor: '#CC0000' },
                              mt: 3, // Increased margin top
                              px: 4, // Increased horizontal padding
                              py: 1.5, // Increased vertical padding
                              fontSize: '1.1rem', // Slightly increased font size
                            }}
                          >
                            Contact Us
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Container>
            </ScrollElement>
          </Box>
        ))}
        
        {isMobile && (
          <Fab
            color="primary"
            variant="extended"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: 1000,
              backgroundColor: '#FF0000',
              '&:hover': {
                backgroundColor: '#CC0000',
              },
              padding: '0 16px',
            }}
            href="https://app.hellodarby.us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography sx={{ fontWeight: 'bold', textTransform: 'none' }}>
              Start now
            </Typography>
            <ArrowForward sx={{ ml: 1 }} />
          </Fab>
        )}

        {/* Add the footer here */}
        <Box
          sx={{
            height: '300px',
            width: '100%',
            backgroundColor: darkPurple,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: 'white',
            mt: 0,
            position: 'relative',
            bottom: 0,
            left: 0,
            right: 0,
            paddingBottom: '30px',
          }}
        >
          <img 
            src={icon} 
            alt="Aging Aircraft Solutions Icon" 
            style={{ height: '60px', marginBottom: '20px' }} 
          />
          <Typography variant="body2" sx={{ mb: 1 }}>
            © {new Date().getFullYear()} Aging Aircraft Solutions. All rights reserved.
          </Typography>
        </Box>
      </Box>
      <Dialog 
        open={openContactDialog} 
        onClose={handleContactDialogClose}
        PaperProps={{
          style: {
            borderRadius: '16px',
            border: '2px solid #000000',
          },
        }}
      >
        <DialogTitle sx={{ 
          backgroundColor: '#000000', 
          color: 'white',
          fontWeight: 'bold'
        }}>
          Contact Us
        </DialogTitle>
        <DialogContent sx={{ 
          paddingTop: '30px',
          '& .MuiTextField-root': { marginBottom: '16px' },
        }}>
          <TextField
            required
            autoFocus
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            value={contactForm.firstName}
            onChange={handleContactFormChange}
            sx={{ 
              '& .MuiOutlinedInput-root': { 
                '&.Mui-focused fieldset': { borderColor: '#FF0000' }
              },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FF0000' },
              marginTop: '30px',
            }}
          />
          <TextField
            required
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={contactForm.lastName}
            onChange={handleContactFormChange}
            sx={{ 
              '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: '#FF0000' } },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FF0000' },
            }}
          />
          <TextField
            required
            margin="dense"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="outlined"
            value={contactForm.phoneNumber}
            onChange={handleContactFormChange}
            inputProps={{ 
              pattern: "[0-9]{10}",
              title: "Phone number must be 10 digits"
            }}
            sx={{ 
              '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: '#FF0000' } },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FF0000' },
            }}
          />
          <TextField
            required
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={contactForm.email}
            onChange={handleContactFormChange}
            inputProps={{
              pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$",
              title: "Enter a valid email address"
            }}
            sx={{ 
              '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: '#FF0000' } },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FF0000' },
            }}
          />
          <TextField
            required
            margin="dense"
            name="comment"
            label="Comment"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={contactForm.comment}
            onChange={handleContactFormChange}
            sx={{ 
              '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: '#FF0000' } },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FF0000' },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: '16px' }}>
          <Button 
            onClick={handleContactDialogClose}
            sx={{ 
              color: '#000000',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleContactFormSubmit} 
            variant="contained" 
            sx={{
              backgroundColor: '#FF0000',
              color: 'white',
              '&:hover': { backgroundColor: '#CC0000' },
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={showSuccessMessage} autoHideDuration={6000} onClose={handleCloseSuccessMessage}>
        <Alert onClose={handleCloseSuccessMessage} severity="success" sx={{ width: '100%' }}>
          Your message has been sent successfully!
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default MainPage;

