import { ContactFormData } from './types'; // Assuming you have a types file

export const sendContactEmail = async (formData: ContactFormData): Promise<boolean> => {
  try {
    // In a real implementation, you would replace this URL with your actual cloud function endpoint
    const cloudFunctionUrl = 'https://us-central1-aa-darby-prod-7fef4.cloudfunctions.net/mail-marketingContact';

    const response = await fetch(cloudFunctionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    const result = await response.json();
    return result.message === "Contact email sent";
  } catch (error) {
    console.error('Error sending contact email:', error);
    return false;
  }
};
