import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#0061fe',
    },
    secondary: {
      main: '#00a82d',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#1e1919',
      secondary: '#637381',
    },
  },
  typography: {
    fontFamily: '"Atlas Grotesk Web", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
});

export const authTextFieldStyle = () => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e8e8e8',
    },
    '&:hover fieldset': {
      borderColor: '#0061fe',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0061fe',
    },
  },
});

export default theme;
